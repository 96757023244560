import { Route, Routes } from "react-router-dom";
import Home from "./page/home/page";
import ServerAccess from "./page/serverAccess/page";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/server/vps/connection" element={<ServerAccess />} />
    </Routes>
  );
};

export default App;
