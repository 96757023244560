import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { useCookies } from "react-cookie";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import css from "./page.module.css";
import { Helmet } from "react-helmet-async";
const Home = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <>
      <Helmet>
        <title>FUJIOS.NET</title>
      </Helmet>
      <div className={css.main}>
        <div className={css.top}>
          <div>
            {cookies.userName ? (
              <Avatar>
                <a href="https://account.fujios.net/account">
                  <AvatarImage src={cookies["imageUrl"]} />
                </a>
              </Avatar>
            ) : (
              <a href="https://account.fujios.net/login">
                <Button>ログイン</Button>
              </a>
            )}
          </div>
        </div>
        <div className={css.main1}>
          <Card>
            <CardHeader>
              <div className={css.cardTop}>
                <svg viewBox="0 0 82.560875 82.56089" version="1.1">
                  <g transform="translate(-111.32389,210.89657)">
                    <rect
                      className={css.dokomeroFill}
                      style={{
                        fillOpacity: 1,
                        strokeWidth: 1,
                        paintOrder: "stroke fill markers",
                      }}
                      width="82.560883"
                      height="82.560883"
                      x="111.32388"
                      y="-210.89658"
                      ry="11.690037"
                    />
                    <path
                      className={css.dokomeroText}
                      d="m 137.19067,-154.83306 c 8.46483,0 14.94509,-6.43975 14.94509,-14.78308 0,-8.38383 -6.48026,-14.78308 -15.02609,-14.78308 -8.54584,0 -15.0261,6.39925 -15.0261,14.74258 0,8.34333 6.56126,14.82358 15.1071,14.82358 z m -0.081,-5.79173 c -5.10321,0 -8.50534,-3.64514 -8.50534,-9.03185 0,-5.38671 3.40213,-8.95086 8.50534,-8.95086 5.0627,0 8.50533,3.60465 8.50533,8.95086 0,5.38671 -3.40213,9.03185 -8.50533,9.03185 z m 19.07624,5.30571 h 6.35875 v -17.13217 l 13.36553,17.13217 h 4.65768 v -28.59412 h -6.35875 v 17.37518 l -13.56803,-17.37518 h -4.45518 z"
                      style={{
                        fontWeight: "bold",
                        fontSize: "40.5016px",
                        fontFamily: "Outfit",
                        InkscapeFontSpecification: '"Outfit, Bold"',
                        letterSpacing: 0,
                        strokeWidth: "2.93809",
                        paintOrder: "stroke fill markers",
                      }}
                      aria-label="ON"
                    />
                  </g>
                </svg>
                <CardTitle>プライベート発車メロディー</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <CardDescription>
                オリジナルの音源を発車ベルスイッチでシミュレートできます。
              </CardDescription>
            </CardContent>
            <CardFooter>
              <a href="https://phm.fujios.net" target="_blank">
                <Button>サイトに行く</Button>
              </a>
            </CardFooter>
          </Card>
          <Card>
            <CardHeader>
              <div className={css.cardTop}>
                <svg viewBox="0 0 411.67435 315.9549" version="1.1">
                  <path
                    d="m 836.56277,1553.3996 c -40.69528,0 -73.43534,32.5034 -73.43534,72.7304 0,40.2271 32.74006,72.7284 73.43534,72.7284 40.6952,0 73.43583,-32.5013 73.43583,-72.7284 0,-40.227 -32.74063,-72.7304 -73.43583,-72.7304 z m -140.27984,44.3751 a 12.50125,12.50125 0 0 0 -12.30261,10.2846 c -15.46092,85.8585 -59.28087,165.7194 -135.5664,239.8283 a 12.50125,12.50125 0 0 0 8.70903,21.4669 H 750.46102 943.7986 a 12.50125,12.50125 0 0 0 8.7111,-21.4669 c -43.8947,-42.6423 -76.78363,-87.2834 -99.94433,-133.7877 -5.1986,0.9299 -10.5406,1.4422 -16.0026,1.4422 -49.63335,0 -90.11908,-40.0905 -90.11908,-89.4121 0,-9.9116 1.65951,-19.4396 4.67517,-28.3553 h -0.65784 z"
                    transform="translate(-544.62465,-1553.3996)"
                  />
                </svg>
                <CardTitle>FUJIOS.NET</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <CardDescription>
                様々な便利サービスを提供することを目的に活動しています。
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <div className={css.cardTop}>
                <svg viewBox="0 0 82.560875 82.56089" version="1.1">
                  <g transform="translate(-111.32389,210.89657)">
                    <rect
                      className={css.dokomeroFill}
                      style={{
                        fillOpacity: 1,
                        strokeWidth: 1,
                        paintOrder: "stroke fill markers",
                      }}
                      width="82.560883"
                      height="82.560883"
                      x="111.32388"
                      y="-210.89658"
                      ry="11.690037"
                    />
                    <path
                      className={css.dokomeroText}
                      d="m 137.19067,-154.83306 c 8.46483,0 14.94509,-6.43975 14.94509,-14.78308 0,-8.38383 -6.48026,-14.78308 -15.02609,-14.78308 -8.54584,0 -15.0261,6.39925 -15.0261,14.74258 0,8.34333 6.56126,14.82358 15.1071,14.82358 z m -0.081,-5.79173 c -5.10321,0 -8.50534,-3.64514 -8.50534,-9.03185 0,-5.38671 3.40213,-8.95086 8.50534,-8.95086 5.0627,0 8.50533,3.60465 8.50533,8.95086 0,5.38671 -3.40213,9.03185 -8.50533,9.03185 z m 19.07624,5.30571 h 6.35875 v -17.13217 l 13.36553,17.13217 h 4.65768 v -28.59412 h -6.35875 v 17.37518 l -13.56803,-17.37518 h -4.45518 z"
                      style={{
                        fontWeight: "bold",
                        fontSize: "40.5016px",
                        fontFamily: "Outfit",
                        InkscapeFontSpecification: '"Outfit, Bold"',
                        letterSpacing: 0,
                        strokeWidth: "2.93809",
                        paintOrder: "stroke fill markers",
                      }}
                      aria-label="ON"
                    />
                  </g>
                </svg>
                <CardTitle>どこでも発車メロディー</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <CardDescription>
                鉄道のメロディー関連をウェブ上でシミュレートできるサービスです。
              </CardDescription>
            </CardContent>
            <CardFooter className="gap-1">
              <a href="https://hassyaberu.com" target="_blank">
                <Button>サイトに行く</Button>
              </a>
            </CardFooter>
          </Card>
        </div>
        <div className={css.main2}>
          <h2>その他のリンク</h2>
          <div className={css.otherButtonList}>
            <a href="/server/vps/connection">
              <Button>サーバー混雑状況</Button>
            </a>
            <a href="https://status.fujios.net" target="_black">
              <Button>サーバー稼働状況</Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
