import { Helmet } from "react-helmet-async";
import css from "./page.module.css";
import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { Skeleton } from "../../components/ui/skeleton";

const ServerAccess = () => {
  const [data, setData] = useState(null);
  const [congestionImgPath, setCongestionImgPath] = useState(null);
  const [congestionMessage, setCongestionMessage] = useState(null);
  useEffect(() => {
    fetch("https://api.fujios.net/server/access-int", { method: "GET" })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  useEffect(() => {
    if (data) {
      const connectionInt = data["connectionInt"];
      if (connectionInt > 0 && connectionInt <= 100) {
        setCongestionImgPath("/congestion/1.png");
        setCongestionMessage("空いている");
      } else if (connectionInt > 100 && connectionInt <= 300) {
        setCongestionImgPath("/congestion/2.png");
        setCongestionMessage("普通");
      } else if (connectionInt > 300 && connectionInt <= 550) {
        setCongestionImgPath("/congestion/3.png");
        setCongestionMessage("混雑");
      } else if (connectionInt > 550) {
        setCongestionImgPath("/congestion/4.png");
        setCongestionMessage("大混雑");
      }
    }
  }, [data]);
  return (
    <>
      <Helmet>
        <title>サーバー混雑度</title>
      </Helmet>
      <div className={css.main}>
        {data ? (
          <div className={css.mainCard}>
            <div className={css.topDiv}>
              <span>{data["updateTime"]}現在</span>
              <p>現在のサーバー混雑状況は以下の通りです</p>
            </div>
            <div className={css.contentDiv}>
              <img src={congestionImgPath} />
              <span>{congestionMessage}</span>
            </div>
            <div className={css.dousetuDiv}>
              <p>サーバー同時接続数:{data["connectionInt"]}</p>
            </div>
            <div className={css.buttonDiv}>
              <a href="/" className="w-full">
                <Button className="w-full">サービス一覧</Button>
              </a>
              <a
                className="w-full"
                href="https://status.fujios.net"
                target="_black"
              >
                <Button className="w-full">サーバーステータス</Button>
              </a>
            </div>
          </div>
        ) : (
          <Skeleton className={css.loadingCard} />
        )}
      </div>
    </>
  );
};

export default ServerAccess;
